import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { useGlobalState } from "../state/globalState";
import DownloadButtons from "./DownloadButtons";
import PlanetLeaf from "./PlanetLeaf";

const query = graphql`
  query DownloadTeaserRewardQuery {
    site {
      siteMetadata {
        downloadActive
      }
    }
  }
`;

export interface IDownloadTeaserRewardData {
  site: {
    siteMetadata: {
      downloadActive: string;
    };
  };
}

export interface DownloadTeaserRewardProps {
  value: number;
}

const DownloadTeaserReward: React.FC<DownloadTeaserRewardProps> = ({
  value,
}) => {
  const data: IDownloadTeaserRewardData = useStaticQuery(query);
  const { t } = useTranslation(["partners", "common"]);
  const [utmTags] = useGlobalState("utmTags");

  const [isSignupPopupOpen, setIsSignupPopupOpen] =
    useGlobalState("isSignupPopupOpen");

  const handleOpenSignupPopup = React.useCallback(() => {
    setIsSignupPopupOpen(true);
  }, []);

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  return (
    <div className="flex flex-col my-12 overflow-hidden transition-shadow duration-300 bg-gray-100 shadow-lg sm:flex-row rounded-2xl hover:shadow-xl">
      <div className="relative flex items-center justify-center w-full h-24 bg-green-500 sm:w-48 sm:h-48">
        <div className="flex flex-row items-center justify-center space-x-2">
          <p className="text-3xl font-medium text-white">{value}</p>
          <PlanetLeaf className="w-8 h-8 text-white fill-current" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center p-6 sm:items-start">
        <h2 className="mb-2 text-xl ">{t("partners:download.headline")}</h2>
        <p className="mb-6 text-gray-500">{t("partners:download.subline")}</p>
        {data.site.siteMetadata.downloadActive ? (
          <>
            <a
              className="button-green-filled md:hidden"
              href={`/download${
                utmTags && utmTags !== "" ? "?" : ""
              }${utmTags}`}
            >
              {t("common:download.general")}
            </a>

            <DownloadButtons
              className="hidden md:block"
              onClick={() => {
                setIsDownloadPopupOpen(true);
              }}
            />
          </>
        ) : (
          <>
            <button
              onClick={handleOpenSignupPopup}
              className="w-full px-10 py-3 text-sm font-medium tracking-wide text-white uppercase bg-green-500 rounded-lg md:hidden hover:shadow-md font-headline"
            >
              {t("common:download.general")}
            </button>

            <DownloadButtons onClick={handleOpenSignupPopup} />
          </>
        )}
      </div>
    </div>
  );
};

export default DownloadTeaserReward;
